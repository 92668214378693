import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {initAxios} from '../api/rest';
import AppContent from "./AppContent";
import HotSwappingIntlProvider from '../intl/ConnectedIntlProvider';
import {authenticateAndRedirect, getAuthenticatedUser, processAuthenticationRedirect} from '../utils/auth';
import piwik from "../constants/piwikConfig";
import get from 'get-value';

class App extends Component {
    componentWillMount() {
        if (App.redirectedFromIdp(this.props.history)) {
            this.processAuthenticationRedirectResult();
        } else {
            getAuthenticatedUser().then(authenticatedUser => {
                if (!authenticatedUser || Object.keys(authenticatedUser).length === 0 || authenticatedUser.expired) {
                    authenticateAndRedirect();
                } else if (!this.props.userDetail || Object.keys(this.props.userDetail).length === 0) {
                    this.processAuthenticatedUser(authenticatedUser);
                    piwik.setUserId(get(authenticatedUser, 'profile.uid', {default: undefined}));
                    piwik.push(['trackPageView']);
                }
            });
        }
    }

    static redirectedFromIdp({location}) {
        const urlParams = new URLSearchParams(location.search);
        const code = urlParams.get('code');
        return (code && code !== null);
    }

    processAuthenticationRedirectResult = () => {
        processAuthenticationRedirect().then(
            ({user, lastVisitedPath}) => {
                this.processAuthenticatedUser(user);
                this.props.history.push(lastVisitedPath);
                piwik.setUserId(get(user, 'profile.uid', {default: undefined}));
                piwik.push(['trackPageView']);
            },
            () => authenticateAndRedirect()
        );
    };

    processAuthenticatedUser = user => {
        initAxios(user);
    };

    render() {
        const {store} = this.props;
        return (
            <HotSwappingIntlProvider {...store}>
                <AppContent />
            </HotSwappingIntlProvider>
        );
    }
}

App.propTypes = {
    // connectWebsockets: PropTypes.func.isRequired,
    // fetchUserRole: PropTypes.func.isRequired,
    // saveUserRole: PropTypes.func.isRequired,
    // isLoading: PropTypes.bool.isRequired,
    // userToBeCreated: PropTypes.bool,
    // store: PropTypes.any.isRequired,
    // history: PropTypes.object.isRequired,
    // location: PropTypes.object.isRequired,
    // userDetail: PropTypes.object
};

const mapStateToProps = state => ({
});


export default withRouter(connect(mapStateToProps, {})(App));